<template>
  <div>
    <slot />
  </div>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  props: {
    query: Object,
    type: String,
    visible: Boolean,
  },

  methods: {
    async onConfirm() {
      if (this.query.period === 'M') {
        var response = await this.$httpd.get(`/reports/${ this.type }/csv`, {
          params: {
            month: this.query.month,
            year: this.query.year,
          },
          responseType: 'blob',
        })
      } else {
        var response = await this.$httpd.get(`/reports/${ this.type }/csv`, {
          params: {
            quarter: this.query.quarter,
            year: this.query.year,
          },
          responseType: 'blob',
        })
      }

      FileSaver.saveAs(response.data, 'report.csv')
    },
  },

  watch: {
    async visible(value) {
      if (value) {
        await this.onConfirm()
        this.$emit('close')
      }
    },
  },
}
</script>
