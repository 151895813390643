<template>
  <div class="card mb-3">
    <div class="card-header">
      <strong>Available Reports</strong>
    </div>
    <div
      v-for="(report, i) in reports"
      :key="i"
      class="card-body py-3"
      :class="{ 'border-bottom': i < reports.length - 1 }"
    >
      <div class="row justify-content-between align-items-center">
        <div class="col-auto">
          <div class="d-flex">
            <div v-if="report.flag" class="mr-3">
              <img width="18" height="18" :src="require(`@/assets/images/countries/${ report.flag }.svg`)">
            </div>
            <div>
              <strong v-if="report.type === 'eu_vat' || report.type === 'ec_sales_list'"><router-link class="text-dark" :to="{ name: `reports-${ report.type }` }">{{ report.title }}</router-link></strong>
              <strong v-else><router-link class="text-dark" :to="{ name: 'reports-type', params: { type: report.type }}">{{ report.title }}</router-link></strong>
              <small>
                <p class="text-muted mb-0">{{ report.description }}</p>
              </small>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <span v-if="report.type === 'eu_vat' || report.type === 'ec_sales_list'">
            <router-link :to="{ name: `reports-${ report.type }` }"><i class="fal fa-chevron-right text-dark"></i></router-link>
          </span>
          <span v-else>
            <router-link :to="{ name: 'reports-type', params: { type: report.type }}"><i class="fal fa-chevron-right text-dark"></i></router-link>
          </span>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <small>
        <p class="mb-0"><i class="fad fa-info-circle mr-1"></i> Reports are a new feature. Please write to <a href="mailto:support@vatstack.com">support@vatstack.com</a> to tell us about any inconsistencies.</p>
      </small>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reports: [{
        title: 'EU OSS',
        description: 'VAT collected for intra-community supplies to end-consumers and remittable to the designated OSS.',
        flag: 'EU',
        type: 'eu_vat',
      }, {
        title: 'EC Sales List (ESL)',
        description: 'Reportable supplies to business customers under the reverse charge mechanism.',
        flag: 'EU',
        type: 'ec_sales_list',
      }],
      memberStates: ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'],
    }
  },

  created() {
    var region = this.$store.state.user.regions.find(item => item.type === 'eu_vat')

    if (region && this.memberStates.includes(region.country_code)) {
      this.reports.push({
        title: 'Domestic',
        description: 'VAT collected for domestic supplies to end-consumers and remittable to your local tax administration.',
        flag: region.country_code,
        type: 'domestic_vat',
      })
    }

    if (this.$store.state.user.regions.find(item => item.type === 'au_gst')) {
      this.reports.push({
        description: 'GST collected from end-consumers in Australia.',
        flag: 'AU',
        title: 'Australia',
        type: 'au_gst',
      })
    }

    if (this.$store.state.user.regions.find(item => item.type === 'no_vat')) {
      this.reports.push({
        description: 'VAT collected from end-consumers in Norway.',
        flag: 'NO',
        title: 'Norway',
        type: 'no_vat',
      })
    }

    if (this.$store.state.user.regions.find(item => item.type === 'ru_vat')) {
      this.reports.push({
        description: 'VAT collected from end-consumers in Russia.',
        flag: 'RU',
        title: 'Russia',
        type: 'ru_vat',
      })
    }

    if (this.$store.state.user.regions.find(item => item.type === 'ch_vat')) {
      this.reports.push({
        title: 'Switzerland',
        description: 'VAT collected from end-consumers in Switzerland.',
        flag: 'CH',
        type: 'ch_vat',
      })
    }

    if (this.$store.state.user.regions.find(item => item.type === 'gb_vat')) {
      this.reports.push({
        description: 'VAT collected from end-consumers in the United Kingdom.',
        flag: 'GB',
        title: 'United Kingdom',
        type: 'gb_vat',
      })
    }
  },
}
</script>
