<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-12 col-md-4">
            <a-radio-group v-model="values.period" @change="getReport">
              <a-radio value="Q">Quarter</a-radio>
              <a-radio value="M">Month</a-radio>
            </a-radio-group>
          </div>
          <div class="col-6 col-md-4">
            <a-select class="w-100" v-model="values.year" @change="getReport">
              <a-select-option v-for="i in 3" :key="getFullYear(i)" :value="getFullYear(i)">
                {{ getFullYear(i) }}
              </a-select-option>
            </a-select>
          </div>
          <div class="col-6 col-md-4">
            <a-select v-if="values.period === 'Q'" class="w-100" v-model="values.quarter" @change="getReport">
              <a-select-option v-for="i in 4" :key="i" :value="i">Q{{ i }}</a-select-option>
            </a-select>
            <a-select v-if="values.period === 'M'" class="w-100" v-model="values.month" @change="getReport">
              <a-select-option v-for="i in 12" :key="i - 1" :value="i - 1">{{ monthNames[i - 1] }}</a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'reports' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Reports</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ regionName }}
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-6 text-right">
            <ReportDownload
              :query="values"
              :type="$route.params.type"
              :visible="visible"
              @close="visible = false"
            >
              <a-button size="small" :loading="visible !== false" @click="visible = true">
                <i class="fal fa-file-csv mr-2"></i> Export CSV
              </a-button>
            </ReportDownload>
          </div>
        </div>
      </div>
      <BaseSpinner :loading="loading">
        <div v-if="periodBegin && periodEnd" class="card-body">
          {{ formatDate(periodBegin) }} <span class="text-muted">to</span> {{ formatDate(periodEnd) }}
        </div>
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="w-25">
                Supplies
              </th>
              <th class="w-25 text-right">
                Taxable Amount
              </th>
              <th class="w-25 text-right">
                VAT Rate
              </th>
              <th class="w-25 text-right">
                VAT Amount
              </th>
            </tr>
          </thead>
          <template v-for="country in countries">
            <thead class="thead-light">
              <tr>
                <th colspan="3">
                  <img width="18" height="18" :src="require(`@/assets/images/countries/${ country.country_code }.svg`)" class="mr-1" style="margin-top: -4px;">
                  {{ country.country_code }} &mdash; {{ $store.state.countries[country.country_code] }}
                </th>
                <th class="text-right">
                  <small class="ml-2"><router-link :to="{ name: 'supplies', query: { country_code: country.country_code, issued_since: periodBegin.slice(0, 10), issued_until: periodEnd.slice(0, 10) }}">View Supplies</router-link></small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(supply, i) in country.supplies" :key="i">
                <td>
                  {{ supply.count }}
                </td>
                <td class="text-right">
                  {{ supply.amount / 100 }} {{ supply.currency }}
                </td>
                <td class="text-right">
                  {{ supply.vat.item }}%
                </td>
                <td class="text-right">
                  {{ supply.vat.amount / 100 }} {{ supply.currency }}
                </td>
              </tr>
            </tbody>
          </template>
          <tr v-if="!countries.length && !loading">
            <td class="text-muted text-center" colspan="5">
              <i class="fad fa-empty-set mr-1"></i> No supplies found for this period.
            </td>
          </tr>
        </table>
      </BaseSpinner>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BaseSpinner from '@/components/Base/BaseSpinner'
import ReportDownload from '@/components/Report/ReportDownload'

export default {
  components: {
    BaseSpinner,
    ReportDownload,
  },

  data() {
    return {
      loading: true,
      visible: false,
      countries: [],
      fixing: null,
      periodBegin: null,
      periodEnd: null,
      values: {
        period: 'Q',
        month: moment().utc().subtract(1, 'month').month(),
        quarter: moment().utc().subtract(1, 'quarter').quarter(),
        year: moment().utc().year(),
      },
    }
  },

  computed: {
    monthNames() {
      return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    regionName() {
      switch (this.$route.params.type) {
        case 'au_gst':
          return 'Australia'
        case 'ch_vat':
          return 'Switzerland'
        case 'domestic_vat':
          return 'Domestic VAT'
        case 'eu_vat':
          return 'EU OSS'
        case 'gb_vat':
          return 'United Kingdom'
        case 'no_vat':
          return 'Norway'
        case 'ru_vat':
          return 'Russia'
      }
    },
  },

  async mounted() {
    await this.getReport()
  },

  methods: {
    async getReport() {
      try {
        this.loading = true

        if (this.values.period === 'M') {
          var { data } = await this.$httpd.get(`/reports/${ this.$route.params.type }`, {
            params: {
              month: this.values.month,
              year: this.values.year,
            }
          })
        } else {
          var { data } = await this.$httpd.get(`/reports/${ this.$route.params.type }`, {
            params: {
              quarter: this.values.quarter,
              year: this.values.year,
            }
          })
        }

        this.countries = data.countries
        this.periodBegin = data.period_begin
        this.periodEnd = data.period_end
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    formatDate(datetime) {
      return datetime.split('T')[0]
    },
    getFullYear(i) {
      return (new Date()).getFullYear() - i + 1
    },
  },
}
</script>
